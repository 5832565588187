import { Select, SelectItem } from "@nextui-org/react";

import { IDropdown } from "./_type";

export * from "./_type";

export default function Dropdown({
  options,
  variant = "bordered",
  onSelectionChange,
  hidden,
  disabled,
  ...props
}: Readonly<IDropdown>) {
  return (
    <Select
      isDisabled={disabled ?? false}
      className={hidden ? "hidden" : ""}
      isInvalid={!!props.error}
      errorMessage={props.error}
      onSelectionChange={(value) =>
        onSelectionChange && onSelectionChange(value)
      }
      variant={variant}
      disabledKeys={options
        .filter((option) => option.disabled)
        .map((option) => option.value.toString())}
      defaultSelectedKeys={props?.value ? [String(props.value)] : []}
      {...props}
    >
      {options.map((item) => (
        <SelectItem
          {...item}
          key={item.value}
          value={item.value}
          aria-label={item.label}
        >
          {item?.badge ? (
            <span
              className="flex justify-center h-8 items-center rounded-lg"
              style={{
                backgroundColor: item.badge.backgroundColor,
                color: item.badge.textColor,
                textAlign: "center",
              }}
            >
              {item.label}
            </span>
          ) : (
            item.label
          )}
        </SelectItem>
      ))}
    </Select>
  );
}
