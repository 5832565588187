import { ICompany } from "./company";
import { EAIType } from "./aiType";
import { ICompanyAssistant } from "./companyAssistant";

export interface IChatApp {
  id: string;
  provider: string;
  company: ICompany;
  companyId: number;
  externalAppId: string;
  title: string;
  botName: string;
  settings?: object;
  aiType?: EAIType;
  assistantId?: string | null;
  companyAssistant: ICompanyAssistant;
}

export enum EChatAppProvider {
  WHATSAPP = "WHATSAPP",
  WIDGET = "WIDGET",
}
