import { IFileUploadResponse, IStorage } from "../../models";
import { AuthService } from "./authService";

export class StorageService {
  public static async fileUploadAsync(
    storage: IStorage,
  ): Promise<IFileUploadResponse> {
    const token = AuthService.getToken();

    const formData = new FormData();
    formData.append("companyId", storage.companyId?.toString() || "");
    formData.append("name", storage.name);

    if (storage.file) {
      formData.append("file", storage.file);
    }

    if (storage.isGlobalAssistantDoc) {
      formData.append(
        "isGlobalAssistantDoc",
        storage.isGlobalAssistantDoc as unknown as string,
      );
    }

    if (storage.id) {
      formData.append("id", storage.id);
    }

    const response = await fetch(
      `${process.env.REACT_APP_AI_THINKS_CB_STORAGE_ENDPOINT}/upload`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      },
    );

    return response.json();
  }
}
